var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-4"},[_c('h3',{staticClass:"text-h3"},[_vm._v(" Договоры ")]),_c('ClearLocalStorage',{attrs:{"default_cols":_vm.cols_default,"storage_name":_vm.cols_storage_name,"cols_list":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols_default=$event}}})],1),_vm._l((_vm.updated_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.table,attrs:{"headers":_vm.updated_cols,"items":_vm.contracts_items,"loading":_vm.loading,"no-data-text":"Договоры отсутствуют"},on:{"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.number_contract",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.min_width},[(item.number_contract)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.number_contract)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.number_contract))])],1)]}},{key:"item.name_contract",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.name_contract)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.name_contract)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.name_contract))])],1)]}},{key:"item.type_of_contract",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translate_contract_type(item.type_of_contract))+" ")]}},{key:"item.mutual_settlements",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translate_mutual_settlements(item.mutual_settlements))+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_active ? 'Активен' : 'Неактивен')+" ")]}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }